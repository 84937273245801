import { Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { IconsService, NotificationService, SocketMiddlewareService, SocketService } from '@core/services';
import { CategoriesService, FightService, PlayersService, TablesService } from '@modules/dashboard/services';
import * as JsBarcode from 'jsbarcode';
import { FightActionEnum } from '@shared/enums';
import { MODAL_CHECK, ROUTES_DATA } from '@shared/constants';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'arm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  currentCode = '';
  init$ = this.socketService.initApp();
  timer = null;
  @HostListener('window:keypress', ['$event'])
  onKeypress(event) {
    this.onScanWithClear(event);
  }
  constructor(
    readonly is: IconsService,
    readonly sm: SocketMiddlewareService,
    readonly socketService: SocketService,
    readonly categoriesService: CategoriesService,
    readonly tablesService: TablesService,
    readonly fightService: FightService,
    readonly notificationService: NotificationService,
    readonly playersService: PlayersService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
  ) {
    this.categoriesService.loadCategoriesState().subscribe(
      () => {
        this.tablesService.loadTables().subscribe();
      }
    );
    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => {
        setTimeout(() => {
          document.getElementById('drawerContent').click();
          if (document.activeElement) {
            (document.activeElement as any).blur();
        }
        });
      })
    );

  }

  onScanWithClear(ev) {
    if (ev.keyCode === 13 && this.currentCode) {
      ev.preventDefault();
      const playerId = +this.currentCode;
      console.log('code', playerId);
      JsBarcode('#ean-13', this.currentCode, {
        format: 'ean13',
        valid: (valid) => {
          console.log('valid');
          if (valid) {
            if (this.router.url === `/${ROUTES_DATA.DASHBOARD.children.PLAYERS.url}` || this.activatedRoute.snapshot.queryParamMap.get(MODAL_CHECK)) {
              this.playersService.setScannedId(playerId);
              setTimeout(() => {
                this.playersService.setScannedId(null);
              }, 500);
            } else {
              const tables = Object.values(this.fightService.getCatTableDataValue()) as any;
              const currTable = tables.find(t => t?.curr?.first?.id === playerId || t?.curr?.second?.id === playerId);
              const currFight = currTable?.curr;
              console.log('currTable', currTable);
              console.log('currFight', currFight);
              if (currFight) {
                this.fightService.fightAction({
                  action: FightActionEnum.Winner,
                  id: currFight.id,
                  person: playerId,
                });
              } else {
                this.notificationService.showError(`${playerId} player not found`);
              }

              const winner = [currFight.first, currFight.second].find(p => p.id === playerId);

              this.notificationService.showSuccess(`${winner.name} - WINNER`);
            }

          }
        }
      });
    } else {
      this.currentCode = this.currentCode + `${ev.key}`;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.currentCode = '';
      }, 500);
    }

  }
}
