import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const ICONS = [
  'armTable',
  'unicornIcon',
  'armtableIcon',
  'categoriesIcon',
  'listIcon',
  'playersIcon',
  'printIcon',
  'resultIcon',
  'screenbroadcastIcon',
  'judgeIcon',
  'scheduleIcon',
];

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    for (const icon of ICONS) {
      try {
        this.matIconRegistry.addSvgIcon(
          icon,
          this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/img/icon/${icon}.svg`)
        );
      } catch (e) {
        console.error(`The icon * ${icon} * is failed`);
      }
    }

  }
}
