<img class="aboutImg" src="{{aboutImg}}" />
<h2>Version: 1.0.0</h2>
<p>Contacts:
    armrec@gmail.com</p>
<div class="dev-text">
    <div class="dev-stl"> Developers:</div>
    <div> Roman Lisnyi</div>
    <div> Eduard Serdyuk</div>
    <div> Nazaryi Ivanytskyi</div>
</div>
<p></p>
<button mat-button mat-dialog-close>Ok</button>