import { Pipe, PipeTransform } from '@angular/core';
import { ICategory, IPlayer } from '@shared/interfaces';
import { AgeGroupEnum, HandEnum } from '@shared/enums';

@Pipe({
  name: 'filterPlayerCat'
})
export class FilterPlayerCatPipe implements PipeTransform {
  transform(list: ICategory[], form: IPlayer) {
    return list
      ?.filter(c =>
        // (form?.weight ? +c.weight.replace('+', '') >= +form.weight - 1 : true) &&
        (form?.gender ? c.gender === form.gender : true) &&
        (form?.hand ? form?.hand === HandEnum.Both ? true : c.hand === form.hand : true) &&
        this.filterByAge(form?.age, c.age))
      .sort((a, b) => a.age - b.age);
  }

  private filterByAge(formAge: AgeGroupEnum, catAge: AgeGroupEnum) {
    if (formAge === AgeGroupEnum.SubJunior) {
      return catAge !== AgeGroupEnum.Masters && catAge !== AgeGroupEnum.GrandMasters && catAge !== AgeGroupEnum.SeniorGrandMasters;
    }
    if (formAge === AgeGroupEnum.Junior) {
      return catAge === AgeGroupEnum.Junior || catAge === AgeGroupEnum.Younth || catAge === AgeGroupEnum.Senior;
    }
    if (formAge === AgeGroupEnum.Younth) {
      return catAge === AgeGroupEnum.Younth || catAge === AgeGroupEnum.Senior;
    }
    if (formAge === AgeGroupEnum.Senior) {
      return catAge === AgeGroupEnum.Senior;
    }
    if (formAge === AgeGroupEnum.Masters) {
      return catAge === AgeGroupEnum.Masters || catAge === AgeGroupEnum.Senior;
    }
    if (formAge === AgeGroupEnum.GrandMasters) {
      return catAge === AgeGroupEnum.GrandMasters || catAge === AgeGroupEnum.Masters || catAge === AgeGroupEnum.Senior;
    }
    if (formAge === AgeGroupEnum.SeniorGrandMasters) {
      return catAge === AgeGroupEnum.SeniorGrandMasters || catAge === AgeGroupEnum.Masters || catAge === AgeGroupEnum.Senior || catAge === AgeGroupEnum.GrandMasters;
    }
    return true;
  }
}
