import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './components/';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AboutmodalComponent } from './components/header/aboutmodal/aboutmodal.component';
import { HttpClientModule } from "@angular/common/http";



@NgModule({
  declarations: [LayoutComponent, HeaderComponent, FooterComponent, SidebarComponent, AboutmodalComponent],
  imports: [RouterModule, SharedModule, HttpClientModule,  ],
  entryComponents: [AboutmodalComponent]
})
export class LayoutModule {}
