import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { SHARED_COMPONENTS, MATERIAL_MODULES } from './components';
import { SHARED_PIPES } from '@shared/pipes';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTreeModule} from '@angular/material/tree';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';



const MODULES = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule];

@NgModule({
  imports: [ ...MATERIAL_MODULES, ...MODULES, MatDialogModule, MatTreeModule, MatSlideToggleModule],
  declarations: [...SHARED_COMPONENTS, ...SHARED_PIPES],
  exports: [
    ...MODULES,
    ...SHARED_COMPONENTS,
    ...MATERIAL_MODULES,
    ...SHARED_PIPES,
    MatDialogModule,
    MatTreeModule,
    MatSlideToggleModule
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class SharedModule {}
