export enum ReqResTypeEnum {
  EVENT = 1, // name, date, place, categories
  CATEGORY = 2, // add category into current event, ... table?
  TABLE = 3, // pin category to the table
  DRAW = 4,
  FIGHT = 6, // get next fight(s) by category/table with depth=1..N. It could be parametrized by category otherwise the back end returns current category of the table
  PERSON = 7, // foul, win, appeal, appeal approved - each fight has uniq number per competition so you can easily change status of the fight
  NEXT = 8, // sportsman, referee, ...
  PREV = 9,
  CUSTOM = 10,
}
