<div class="sidenav-list">
  <mat-nav-list *ngFor="let item of menu">
    <a mat-list-item [routerLink]="'/' + item.url" routerLinkActive="active">
      <mat-icon class="icon-color"svgIcon="{{ item.icon }}"></mat-icon>
      <span class="text-el">{{ item.title }}</span>
    </a>
  </mat-nav-list>
</div>

  


