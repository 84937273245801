import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ActionEnum, ReqResTypeEnum } from '../../shared/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { IReqRes } from '@shared/interfaces';
import { ModalsService } from '@core/services/modals.service';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  init$ = new BehaviorSubject(false);
  constructor(readonly socket: Socket,
              readonly modalsService: ModalsService) {
    this.handlerSocketErrors().subscribe();
    this.initialLoad().subscribe();
  }

  sendSocketMessage<T>(
    type: ReqResTypeEnum,
    data: T,
    action: ActionEnum = null
  ) {

    const req = {
      type,
      data,
    };

    if (action) {
      if (action === ActionEnum.Remove || action === ActionEnum.Stat) {
        (req as any).objectId = (data as any)?.id;
        delete req.data;
      }
      (req as any).action = action;
    }

    if ((data as any)?.payload) {
      (req as any).payload = (data as any).payload;
      delete (data as any).payload;
    }
    (req as any).dtf = new DatePipe('en').transform(new Date(), 'dd-MM-YYYY HH:mm:ss');
    console.log('req', req);
    this.socket.emit('request', req);
  }

  handlerSocketMessages(): Observable<[IReqRes<any>, boolean]> {
    // @ts-ignore
    return this.socket.fromEvent('response').pipe(
      withLatestFrom(this.init$)
    );
  }

  initialLoad(): Observable<IReqRes<any>> {
    return this.socket.fromEvent('admin').pipe(
      tap((res: any) => {
        console.log('admin', res);
        if (res.status === 2) {
          console.log('**** APP INITIALIZED ****');
          this.init$.next(true);
        } else {
          const init = this.init$.getValue();
          if (init) {
            this.init$.next(false);
          }
        }
      })
    );
  }

  handlerSocketErrors() {
    return this.socket.fromEvent('request-rejected').pipe(
      map((data) => {
        this.modalsService.openConfirmPopup(
          {
            header: 'ERROR',
            body: `${data}`,
            buttons: [null, { name: 'Confirm' }],
          },
          () => {}
        );
        return data;
      })
    );
  }

  initApp() {
    return this.init$.asObservable();
  }
}
