export enum CategoryStateEnum {
  InProgress = 1, // common fight
  ToFinal = 2,
  SemiFinal = 3,
  Final = 4,
  SuperFinal = 5,
  Completed = 6,
  Init = 7,
  Draw = 8,
  Closed = 9,
}
