import { Observable, Subject } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';

export interface IDestroyable extends Partial<OnDestroy> {
  readonly destroyed$: Observable<unknown>;
  ngOnDestroy?(): void;
}

const destroySubject = Symbol('destroySubject');

@Injectable()
export abstract class Destroyable implements IDestroyable, OnDestroy {
  readonly [destroySubject] = new Subject();
  destroyed$ = this[destroySubject].asObservable();

  ngOnDestroy() {
    this[destroySubject].next();
    this[destroySubject].complete();
  }

  public recreateDestroy() {
    this.ngOnDestroy();

    this.destroyed$ = this[destroySubject].asObservable();
  }
}
