import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'arm-aboutmodal',
  templateUrl: './aboutmodal.component.html',
  styleUrls: ['./aboutmodal.component.scss']
})
export class AboutmodalComponent implements OnInit {
  aboutImg = '/assets/logo.svg';
  
  constructor (public dialogRef: MatDialogRef<AboutmodalComponent>) { }

  ngOnInit(): void {
  }

}
