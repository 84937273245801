import { Injectable } from '@angular/core';
import { LANG_KEY, VALUES } from '@shared/constants';
import { LangEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class ValuesMapService {
  lang = localStorage.getItem(LANG_KEY)?.toUpperCase() || LangEnum.EN;
  // tslint:disable-next-line:variable-name
  private _gendersMap = VALUES[this.lang].genders.reduce((acc, el) => {
    acc[el.key] = el.value;
    return acc;
  }, {} as any);

  // tslint:disable-next-line:variable-name
  private _ageGroupMap = VALUES[this.lang].ageGroups.reduce((acc, el) => {
    acc[el.key] = el.value;
    return acc;
  }, {} as any);

  // tslint:disable-next-line:variable-name
  private _handsMap = VALUES[this.lang].hands.reduce((acc, el) => {
    acc[el.key] = el.value;
    return acc;
  }, {} as any);

  constructor() { }

  get gendersMap() {
    return this._gendersMap;
  }

  get ageGroupMap() {
    return this._ageGroupMap;
  }

  get handsMap() {
    return this._handsMap;
  }

  get catStatusesMap() {
    return VALUES.EN.catStatuses;
  }
}
