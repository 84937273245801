import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AuthService } from '@core/services';
import { Router } from '@angular/router';
import { ROUTES_DATA } from '@shared/constants';

@Component({
  selector: 'arm-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent {
  @Input() eventPage = false;
  @Input() title = '';

  constructor(readonly authService: AuthService, readonly router: Router) {}

  changeEvent() {
    // this.router.navigateByUrl(ROUTES_DATA.EVENTS.url);
  }
}
