import { Component, OnInit, ChangeDetectionStrategy, Output } from '@angular/core';
import { AuthService } from 'src/app/core/services';
import { LANG_KEY, ROUTES_DATA } from 'src/app/shared/constants';
import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AboutmodalComponent } from './aboutmodal/aboutmodal.component';
import { Router } from '@angular/router';
import { LangEnum } from '@shared/enums';


@Component({
  selector: 'arm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  LangEnum = LangEnum;
  ROUTES_DATA = ROUTES_DATA;

  headerLogo = '/assets/Logo ArmRec_white2.svg';

  settingsMenu() {this.router.navigateByUrl(ROUTES_DATA.DASHBOARD.children.SETTINGS.url)}


  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  constructor(private authService: AuthService, public dialog: MatDialog, private router: Router) {}

  @Output() SideNavToggle = new EventEmitter();

  openSidenav() {
   this.SideNavToggle.emit();
}

  ngOnInit() {}

  toggleSideBar() {
this.toggleSideBarForMe.emit();

  }



  logout() {
    this.authService.logout();
  }

  openAbout() {
    this.dialog.open(AboutmodalComponent, {
       width: '1000px'
     });
 }

  selectLang(lang: LangEnum) {
    localStorage.setItem(LANG_KEY, lang);
    window.location.reload();
  }

}
