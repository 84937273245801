import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PrintEnum } from '@shared/enums';
import { COUNT_PLAYERS_FOR_PRINT, ROUTES_DATA } from '@shared/constants';
import { Location } from '@angular/common';
import { PlayersService } from '@modules/dashboard/services';

const PRINT_COMPONENTS = {
  [PrintEnum.PlayerCodes]: ROUTES_DATA.PRINTER.children.PLAYERS.url,
  [PrintEnum.DiplomaPrint]: ROUTES_DATA.PRINTER.children.DIPLOMAS.url,
  [PrintEnum.Results]: ROUTES_DATA.PRINTER.children.RESULTS.url,
  [PrintEnum.PlayersList]: ROUTES_DATA.PRINTER.children.LIST.url
};

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(readonly router: Router,
              readonly playersService: PlayersService,
              readonly location: Location) {
  }

  printDocument(type: PrintEnum, params = {}, documentName = 'Arm_doc') {
    this.isPrinting = true;
    if (type === PrintEnum.PlayerCodes) {
      const queryArr = [];
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          queryArr.push(`${key}=${params[key]}`);
        }
      }
      const query = queryArr.join('&');

      if (!query) { // print list
        const playerSize = this.playersService.getPlayersCount();
        const pages = Math.ceil(playerSize / COUNT_PLAYERS_FOR_PRINT);
        [...Array(pages).keys()].forEach(p => {
          window.open(window.location.origin + `/${PRINT_COMPONENTS[type]}?page=${p}`);
        });
      } else {
        window.open(window.location.origin + `/${PRINT_COMPONENTS[type]}` + (query ? `?${query}` : ''));
      }

    } else {
      this.router.navigate([`/${PRINT_COMPONENTS[type]}`], {
        queryParams: params
      });
    }
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.location.back();
    }, 500);
  }

  downloadFile(csv, fileName) {
    const blob = new Blob(["\uFEFF" + csv], {type: 'text/csv;charset=utf-18'});

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
