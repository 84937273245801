import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES_DATA } from '@shared/constants';
import { UnauthGuard, AuthGuard } from '@core/guards';
import { LayoutComponent } from '../layout/layout.component';

const routes: Routes = [
  {
    path: ROUTES_DATA.VIDEO.url,
    loadChildren: () => import('@modules/video/video.module').then((m) => m.VideoModule),
  },
  {
    path: ROUTES_DATA.PRINTER.url,
    loadChildren: () => import('@modules/print/print.module').then((m) => m.PrintModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: ROUTES_DATA.AUTH.url,
        loadChildren: () =>
          import('@modules/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [UnauthGuard],
      },
      {
        path: ROUTES_DATA.DASHBOARD.url,
        loadChildren: () =>
          import('@modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {path: '**', redirectTo: '/404'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RoutingModule {
}
