import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'arm-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {

  

  sideBarOpen=true;


  constructor() {
  }

  ngOnInit() {}

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }
}
