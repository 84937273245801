import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IReqRes, ITable } from '../../../shared/interfaces';
import { ActionEnum, ReqResTypeEnum } from '../../../shared/enums';
import { SocketService } from '../../../core/services';
import { processEntity, processMapEntity } from '../../../shared/helpers';
import { HttpClient } from '@angular/common/http';
import { CategoriesService } from '@modules/dashboard/services/categories.service';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TablesService {
  private tablesMap = new Map();
  private tables$ = new BehaviorSubject<ITable[]>([]);

  constructor(
    readonly socketService: SocketService,
    readonly categoriesService: CategoriesService,
    readonly http: HttpClient,
  ) {
  }

  processing(res: IReqRes<any>) {
    res.data.categories = res.data.categories.map(c => this.categoriesService.getCategoriesMap(c));
    processMapEntity(this.tablesMap, res);
    this.tables$.next(Array.from(this.tablesMap.values()));
  }

  getTables() {
    return this.tables$.asObservable();
  }

  getTablesMap(id: number = null) {
    if (id) {
      return this.tablesMap.get(id);
    }
    return this.tablesMap;
  }

  loadTableState(id: number) {
    return this.socketService.sendSocketMessage(
      ReqResTypeEnum.TABLE,
      {id},
      ActionEnum.Stat,
    );
  }

  saveTable(data: ITable, action: ActionEnum) {
    this.socketService.sendSocketMessage<ITable>(
      ReqResTypeEnum.TABLE,
      data,
      action
    );
  }

  loadTables() {
    return this.http
      .get<any>(`${environment.apiUrl}tables`)
      .pipe(map((res) => {
        res.forEach(t => {
          if (t.id > 0 && t.categories.length) {
            t.categories = t.categories.map(c => this.categoriesService.getCategoriesMap(c));
            this.tablesMap.set(t.id, t);
          }
        });
        this.tables$.next(Array.from(this.tablesMap.values()));
      }));
  }
}
