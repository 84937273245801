<ng-container *ngIf="init$ | async; else loader">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #loader>
  <div class="frame-loader">
    <div class="loader">
      <span></span>
      <span></span>
    </div>
  </div>
</ng-template>
<div class="d_n">
  <svg id="ean-13"></svg>
</div>
