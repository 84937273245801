<div class="wrapper">
    <arm-header (toggleSideBarForMe)="sideBarToggler()"></arm-header>
    <mat-drawer-container class="layout-dashboard">
        <mat-drawer class="layout-sidebar" mode="side" [opened]="sideBarOpen">
            <arm-sidebar></arm-sidebar>
        </mat-drawer>
        <mat-drawer-content class="layout-content" id="drawerContent">
            <router-outlet></router-outlet>
        </mat-drawer-content>
    </mat-drawer-container>
    <arm-footer></arm-footer>
</div>

