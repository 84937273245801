import { Pipe, PipeTransform } from '@angular/core';
import { ICategory } from '@shared/interfaces';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'sortTableCat'
})
export class SortTableCatPipe implements PipeTransform {

  transform(list: ICategory[], value: any) {
    return list
    .filter(c => this.getCondition(c.age, value.age) && this.getCondition(c.gender, value.gender) && this.getCondition(c.hand, value.hand))
    .sort(this.sortCategory);
  }

  sortCategory(a, b) {
    // Спочатку сортуємо за age
    if (+a.age < +b.age) {
      return -1;
    }
    if (+a.age > +b.age) {
      return 1;
    }

    if (+a.gender > +b.gender) {
      return -1;
    }
    if (+a.gender < +b.gender) {
      return 1;
    }

    // Якщо age однакові, то сортуємо за weight
    const weightA = isNaN(+a.weight) ? Infinity : +a.weight;
    const weightB = isNaN(+b.weight) ? Infinity : +b.weight;

    return weightA - weightB;
  }

  getCondition(catValue: number, formValue: number) {
    if (formValue) {
      return catValue === formValue;
    }
    return true;
  }


}
