<div class="modal">
  <div mat-dialog-title>{{ data.header }}</div>
  <div mat-dialog-content>
    <div *ngIf="data.html; else content" [innerHTML]="data.body"></div>
    <ng-template #content>
      <p>{{ data.body }}</p>
    </ng-template>
  </div>
  <div mat-dialog-actions>
    <button
      *ngIf="data.buttons?.[0]?.name"
      mat-button
      class="btn-grey"
      [mat-dialog-close]="false"
    >
      {{ data.buttons[0].name }}
    </button>
    <button *ngIf="data.buttons[1].name" (click)="confirm()" mat-raised-button color="primary">
      {{ data.buttons[1].name }}
    </button>
  </div>
</div>
