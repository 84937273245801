import { Pipe, PipeTransform } from '@angular/core';
import { IPlayer } from '@shared/interfaces';
import { HandEnum } from '@shared/enums';

@Pipe({
  name: 'filterPlayers'
})
export class FilterPlayersPipe implements PipeTransform {

  transform(list: IPlayer[], form: Partial<IPlayer>) {
    const data = {} as Partial<IPlayer>;
    for (const key in form) {
      if (form.hasOwnProperty(key) && form[key]) {
        data[key] = form[key];
      }
    }
    return list.filter(p => {
      const filter = [];
      if (data.age) {
        filter.push(p.age === data.age);
      }
      if (data.gender) {
        filter.push(p.gender === data.gender);
      }
      if (data.hand) {
        filter.push(p.hand === HandEnum.Both ? true : p.hand  === data.hand);
      }
      if (data.name) {
        filter.push(p.name.toLowerCase().includes(data.name.toLowerCase()));
      }
      if (data.categories?.length) {
        filter.push(!!p.categories.find(c => data.categories.includes(c)));
      }
      if (data.team) {
        filter.push(p.team.toLowerCase().includes(data.team.toLowerCase()));
      }
      if (data.isConfirmed === 'no') {
        filter.push(!p.confirmed);
      }
      if (data.isConfirmed === 'yes') {
        filter.push(p.confirmed);
      }

      return filter.length === filter.filter(Boolean).length;
    });
  }

}
