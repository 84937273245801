import {
  GenderEnum,
  AgeGroupEnum,
  HandEnum,
  FightTypeEnum,
  FightGroupEnum,
  ResultTableEnum,
  CategoryStateEnum
} from '@shared/enums';

export const VALUES = {
  EN: {
    genders: [
      { key: GenderEnum.Female, value: 'Women' },
      { key: GenderEnum.Male, value: 'Men' },
    ],
    ageGroups: [
      { key: AgeGroupEnum.MiniSubJunior, value: 'Sub-Junior 13' },
      { key: AgeGroupEnum.SubJunior, value: 'Sub-Junior 15' },
      { key: AgeGroupEnum.Junior, value: 'Junior' },
      { key: AgeGroupEnum.Younth, value: 'Younth' },
      { key: AgeGroupEnum.Senior, value: 'Senior' },
      { key: AgeGroupEnum.Masters, value: 'Masters 40+' },
      { key: AgeGroupEnum.GrandMasters, value: 'Grand Masters 50+' },
      { key: AgeGroupEnum.SeniorGrandMasters, value: 'Senior Grand Masters 60+' },
      { key: AgeGroupEnum.SuperSeniorGrandMasters, value: 'Super Senior Grand Masters 70+' },
      // пара-армрестлінг
      { key: AgeGroupEnum.PIDsitting, value: 'PID Sitting' },
      { key: AgeGroupEnum.PIDHsitting, value: 'PIDH Sitting' },
      { key: AgeGroupEnum.PIUstanding, value: 'PIU Standing' },
      { key: AgeGroupEnum.PIUjunior23, value: 'PIU Junior 23' },
      { key: AgeGroupEnum.PIUHstanding, value: 'PIUH Standing' },
      { key: AgeGroupEnum.PIUHjunior23, value: 'PIUH Junior 23' },
      { key: AgeGroupEnum.VIVisualstanding, value: 'VI Visual Standing' },
      { key: AgeGroupEnum.VIjunior23, value: 'VI Junior 23' },
      { key: AgeGroupEnum.HIHearingstanding, value: 'HI Hearing Standing' },
      { key: AgeGroupEnum.HIjunior23, value: 'HI Junior 23' },
      { key: AgeGroupEnum.CPDsitting, value: 'CPD Sitting' },
      { key: AgeGroupEnum.CPUstanding, value: 'CPU Standing' },

      

    ],
    hands: [
      { key: HandEnum.Both, value: 'Both' },
      { key: HandEnum.Left, value: 'Left' },
      { key: HandEnum.Right, value: 'Right' },
    ],
    columnTypes: [
      { key: 'text', value: 'Text' },
      { key: 'number', value: 'Number' },
      { key: 'date', value: 'Date' },
      { key: 'checkbox', value: 'Checkbox' },
    ],
    fightTypes: {
      [FightTypeEnum.Common]: 'Common',
      [FightTypeEnum.ToFinal]: 'To Final',
      [FightTypeEnum.SemiFinal]: 'Semi Final',
      [FightTypeEnum.ToSemiFinal]: 'To Semi Final',
      [FightTypeEnum.Final]: 'Final',
      [FightTypeEnum.SuperFinal]: 'Super Final',
      [FightTypeEnum.HappyWinner]: 'Happy Winner',
      [FightTypeEnum.SemiFinalHappyWinner]: 'Semi Final',
    },
    catStatuses: {
      [CategoryStateEnum.InProgress]: 'In Progress',
      [CategoryStateEnum.ToFinal]: 'To Final',
      [CategoryStateEnum.SemiFinal]: 'Semi Final',
      [CategoryStateEnum.Final]: 'Final',
      [CategoryStateEnum.SuperFinal]: 'Super Final',
      [CategoryStateEnum.Completed]: 'Completed',
      [CategoryStateEnum.Init]: 'Init',
      [CategoryStateEnum.Draw]: 'Draw',
      [CategoryStateEnum.Closed]: 'Closed',
    },
    fightGroups: {
      [FightGroupEnum.A]: 'A',
      [FightGroupEnum.B]: 'B',
      [FightGroupEnum.F]: 'F',
    }
  },
  UA: {
    genders: [
      { key: GenderEnum.Female, value: 'Жінки' },
      { key: GenderEnum.Male, value: 'Чоловіки' },
    ],
    ageGroups: [
      { key: AgeGroupEnum.MiniSubJunior, value: 'Юнаки 13' },
      { key: AgeGroupEnum.SubJunior, value: 'Юнаки 15' },
      { key: AgeGroupEnum.Junior, value: 'Юніори' },
      { key: AgeGroupEnum.Younth, value: 'Молодь' },
      { key: AgeGroupEnum.Senior, value: 'Дорослі' },
      { key: AgeGroupEnum.Masters, value: 'Ветерани 40+' },
      { key: AgeGroupEnum.GrandMasters, value: 'Ветерани 50+' },
      { key: AgeGroupEnum.SeniorGrandMasters, value: 'Ветерани 60+' },
      { key: AgeGroupEnum.SuperSeniorGrandMasters, value: 'Ветерани 70+' },


      { key: AgeGroupEnum.PIDsitting, value: 'УОРА сидячи' },
      { key: AgeGroupEnum.PIDHsitting, value: 'УОРА одна рука сидячи' },
      { key: AgeGroupEnum.PIUstanding, value: 'УОРА стоячи' },
      { key: AgeGroupEnum.PIUjunior23, value: 'УОРА стоячи юніори 23' },
      { key: AgeGroupEnum.PIUHstanding, value: 'УОРА одна рука стоячи' },
      { key: AgeGroupEnum.PIUHjunior23, value: 'УОРА одна рука стоячи юніори 23' },
      { key: AgeGroupEnum.VIVisualstanding, value: 'Зір' },
      { key: AgeGroupEnum.VIjunior23, value: 'Зір юніори 23' },
      { key: AgeGroupEnum.HIHearingstanding, value: 'Слух' },
      { key: AgeGroupEnum.HIjunior23, value: 'Слух юніори 23' },
      { key: AgeGroupEnum.CPDsitting, value: 'УОРА дцп сидячи' },
      { key: AgeGroupEnum.CPUstanding, value: 'УОРА дцп стоячи' },


    ],
    hands: [
      { key: HandEnum.Both, value: 'Обидві' },
      { key: HandEnum.Left, value: 'Ліва' },
      { key: HandEnum.Right, value: 'Права' },
    ],
    columnTypes: [
      { key: 'text', value: 'Text' },
      { key: 'number', value: 'Number' },
      { key: 'date', value: 'Date' },
      { key: 'checkbox', value: 'Checkbox' },
    ],
    fightTypes: {
      [FightTypeEnum.Common]: 'Common',
      [FightTypeEnum.ToFinal]: 'To Final',
      [FightTypeEnum.ToSemiFinal]: 'To Semi Final',
      [FightTypeEnum.SemiFinal]: 'Semi Final',
      [FightTypeEnum.Final]: 'Final',
      [FightTypeEnum.SuperFinal]: 'Super Final',
      [FightTypeEnum.HappyWinner]: 'Happy Winner',
      [FightTypeEnum.SemiFinalHappyWinner]: 'Semi Final',
    },
    fightGroups: {
      [FightGroupEnum.A]: 'A',
      [FightGroupEnum.B]: 'B',
      [FightGroupEnum.F]: 'F',
    }
  },
};
