import { Injectable } from '@angular/core';
import { ActionEnum, FightTypeEnum, ReqResTypeEnum } from '../../shared/enums';
import { SocketService } from './socket.service';
import {
  CategoriesService,
  EventService,
  FightService,
  PlayersService,
  TablesService
} from '../../modules/dashboard/services';
import { CustomEventsService } from '@core/services/custom-events.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SocketMiddlewareService {
  constructor(readonly socketService: SocketService,
              readonly playersService: PlayersService,
              readonly categoriesService: CategoriesService,
              readonly tablesService: TablesService,
              readonly eventService: EventService,
              readonly customEventsService: CustomEventsService,
              readonly fightService: FightService) {
    this.socketService.handlerSocketMessages()
      .pipe(filter(([res, init]) => init || res.type === ReqResTypeEnum.EVENT || res.type === ReqResTypeEnum.CUSTOM))
      .subscribe(
        ([res, init]) => {
          console.log('res', res);
          switch (res.type) {
            case ReqResTypeEnum.CATEGORY: {
              if (res.req.action === ActionEnum.Stat) {
                this.categoriesService.setCategoryState(res.data);
              }
              break;
            }
            case ReqResTypeEnum.PERSON: {
              this.playersService.processing(res);
              break;
            }
            case ReqResTypeEnum.NEXT: {
              this.fightService.processingNext(res);
              break;
            }
            case ReqResTypeEnum.PREV: {
              this.fightService.processingPrev(res);
              break;
            }
            case ReqResTypeEnum.FIGHT: {
              this.fightService.processingFight(res);
              break;
            }
            case ReqResTypeEnum.EVENT: {
              this.eventService.processing(res);
              break;
            }
            case ReqResTypeEnum.TABLE: {
              this.tablesService.processing(res);
              break;
            }
            case ReqResTypeEnum.CUSTOM: {
              this.customEventsService.processing(res);
              break;
            }
          }
        }
      );
  }
}
