import { Injectable } from '@angular/core';
import { LANG_KEY, VALUES } from '@shared/constants';
import { IColumnType } from '@shared/interfaces';
import { LangEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class ValuesService {
  lang = localStorage.getItem(LANG_KEY)?.toUpperCase() || LangEnum.EN;
  constructor() {}

  get genders() {
    return VALUES[this.lang].genders;
  }

  get ageGroup() {
    return VALUES[this.lang].ageGroups;
  }

  get hands() {
    return VALUES[this.lang].hands;
  }

  get fightTypes() {
    return VALUES[this.lang].fightTypes;
  }

  get fightGroups() {
    return VALUES[this.lang].fightGroups;
  }

  get columnTypes() {
    return VALUES[this.lang].columnTypes as IColumnType[];
  }
}
