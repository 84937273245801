<ng-container *ngIf="event$ | async"></ng-container>
<div>
  <form class="settings-form" *ngIf="form" action="" [formGroup]="form" (ngSubmit)="saveEvent()">
    <div class="settings">
      <div class="title-align">
        <div class="settings__title">settings</div>
      </div>
    </div>
    <div class="event">
      <div class="event__title">Event information</div>
      <div class="event__name">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" required/>
        </mat-form-field>
      </div>
      <div class="event__place">
        <mat-form-field appearance="outline">
          <mat-label>Place</mat-label>
          <input matInput formControlName="place"/>
        </mat-form-field>
      </div>
      <div class="event__date">
        <mat-form-field appearance="outline">
          <mat-label>Enter start and end date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [formControl]="form.controls['startDate']" placeholder="Start date"/>
            <input matEndDate [formControl]="form.controls['endDate']" placeholder="End date"/>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <!--<div>
      <mat-form-field appearance="outline">
        <mat-label>Age group</mat-label>
        <mat-select formControlName="age" multiple>
          <mat-option *ngFor="let option of ageGroup" [value]="option.key">{{
            option.value
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>-->
    </div>

    <div class="points" formGroupName="placesScore">
      <div class="points__title">Place score</div>
      <div class="place-align">
        <div class="points__places">
          <div class="place">1st place</div>
          <mat-form-field appearance="outline">
            <mat-label>Points</mat-label>
            <input formControlName="1" matInput required/>
          </mat-form-field>
        </div>
        <div class="points__places">
          <div class="place">2nd place</div>
          <mat-form-field appearance="outline">
            <mat-label>Points</mat-label>
            <input formControlName="2" matInput required/>
          </mat-form-field>
        </div>
      </div>
      <div class="place-align">
        <div class="points__places">
          <div class="place">3rd place</div>
          <mat-form-field appearance="outline">
            <mat-label>Points</mat-label>
            <input formControlName="3" matInput required/>
          </mat-form-field>
        </div>
        <div class="points__places">
          <div class="place">4th place</div>
          <mat-form-field appearance="outline">
            <mat-label>Points</mat-label>
            <input formControlName="4" matInput required/>
          </mat-form-field>
        </div>
      </div>

      <div class="place-align">
        <div class="points__places">
          <div class="place">5th place</div>
          <mat-form-field appearance="outline">
            <mat-label>Points</mat-label>
            <input formControlName="5" matInput required/>
          </mat-form-field>
        </div>
        <div class="points__places">
          <div class="place">6th place</div>
          <mat-form-field appearance="outline">
            <mat-label>Points</mat-label>
            <input formControlName="6" matInput required/>
          </mat-form-field>
        </div>
      </div>

      <div class="place-align">
        <div class="points__places">
          <div class="place">7th place</div>
          <mat-form-field appearance="outline">
            <mat-label>Points</mat-label>
            <input formControlName="7" matInput required/>
          </mat-form-field>
        </div>
        <div class="points__places">
          <div class="place">Other places</div>
          <mat-form-field appearance="outline">
            <mat-label>Points</mat-label>
            <input formControlName="8" matInput required/>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="settings-form__actions">
      <button class="t-t_u" type="button" mat-button (click)="cancelSave()">
        <mat-icon>close</mat-icon>
        Cancel
      </button>
      <button class="t-t_u" type="submit" mat-button color="primary">
        <mat-icon>save</mat-icon>
        Save
      </button>
    </div>
  </form>
</div>
