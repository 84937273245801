import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'arm-modal-confirm',
  templateUrl: './modal-confirm.component.html',
})
export class ModalConfirmComponent {
  answer = false;

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.beforeClosed().subscribe(() => {
      this.dialogRef.close(this.answer);
    });
  }

  onNoClick(): void {
    this.dialogRef.close(this.answer);
  }

  confirm() {
    this.answer = true;
    this.dialogRef.close(this.answer);
  }
}
