import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ActionEnum,
  AgeGroupEnum,
  CategoryStateEnum,
  FightTypeEnum,
  HandEnum,
  ReqResTypeEnum
} from '../../../shared/enums';
import { ICategory, IEvent } from '../../../shared/interfaces';
import { SocketService, ValuesMapService, } from '../../../core/services';
import { HttpClient } from '@angular/common/http';
import { PlayersService } from '@modules/dashboard/services/players.service';
import { map, take, tap } from 'rxjs/operators';
import { FightService } from '@modules/dashboard/services/fight.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  gendersMap = this.valuesMapService.gendersMap;
  ageGroupMap = this.valuesMapService.ageGroupMap;
  handsMap = this.valuesMapService.handsMap;


  private categoriesMap = new Map();
  private categories$ = new BehaviorSubject<ICategory[]>([]);

  constructor(
    readonly socketService: SocketService,
    readonly valuesMapService: ValuesMapService,
    readonly playersService: PlayersService,
    readonly fightService: FightService,
    readonly http: HttpClient
  ) {
  }

  getCategories() {
    return this.categories$.asObservable();
  }

  getCategoriesMap(id: string = null) {
    if (id) {
      return this.categoriesMap.get(id);
    }
    return this.categoriesMap;
  }

  saveCategory(data: ICategory, action: ActionEnum) {
    this.socketService.sendSocketMessage<ICategory>(
      ReqResTypeEnum.CATEGORY,
      data,
      action
    );
  }

  loadCategoryState(id: string) {
    return this.http
      .get<any>(`${environment.apiUrl}categories/${id}`).pipe(
        map(category => {
          const fights = category.fights
            .filter(f => !f.invalid)
            .map((f => this.fightService.mapFight(f)));
          return this.mapCategory({
            ...category,
            fights
          });
        }));
  }


  setCategoryState(category: ICategory) {
    const fights = category.fights
      .filter(f => !f.invalid)
      .map((f => this.fightService.mapFight(f)));
    const cat = this.mapCategory({
      ...category,
      fights
    });
    this.categoriesMap.set(cat.id, cat);
  }

  mapCategory(data) {
    return {
      ...data,
      ageValue: this.ageGroupMap[data.age],
      genderValue: this.gendersMap[data.gender],
      handValue: this.handsMap[data.hand],
      alias: `${this.ageGroupMap[data.age]} ${this.gendersMap[data.gender]} - ${data.weight} ${this.handsMap[data.hand].charAt(0)}`
    } as ICategory;
  }

  loadCategoriesState(): Observable<ICategory[]> {
    return this.http
      .get<any>(`${environment.apiUrl}categories`).pipe(
        map(res => {
          console.log('********', res);
          res.active.forEach(c => {
            this.setCategoryState(c);
          });

          const categoriesArr = Array.from(this.categoriesMap.values());
          this.categories$.next(categoriesArr);

          return categoriesArr;
        })
      );
  }

  getCategoriesResults(filterCategories = []): Observable<ReadonlyMap<[key: string], ICategory[]>> {
    return this.loadCategoriesState().pipe(
      take(1),
      map(categories => filterCategories?.length ? categories.filter(c => filterCategories.includes(c.id)) : categories),
      map(categories => categories
        .reduce((acc, cat) => {
          if (cat.state === CategoryStateEnum.Completed) {
            cat.categoryResult = this.playersService.getPlayersByPlace(cat.AllPersonsByPlace.reverse());
            const key = `${cat.ageValue} ${cat.genderValue} - ${cat.handValue}`;
            if (acc[key]) {
              acc[key].push(cat);
            } else {
              acc[key] = [cat];
            }
          }
          return acc;
        }, {} as ReadonlyMap<[key: string], ICategory[]>)
      ),
      map(res => {
        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            res[key] = res[key].sort((a, b) => {
              if (!Number(b.weight)) {
                return -1;
              }
              return +a.weight - +b.weight;
            });
          }
        }
        return res;
      }),
      tap(res => {
        console.log('Category result', res);
      }));
  }

  getSortedCategoriesResults(ages: number[] = [], additional = {}): Observable<ICategory[]> {
    return this.loadCategoriesState().pipe(
      take(1),
      map(categories => categories
        .filter(c => c.state === CategoryStateEnum.Completed && ages.includes(c.age))
        .reduce((acc, c) => {
          const clas = acc.find(d => d.key === c.age);
          if (clas) {
            const genderObg = clas.data.find(d => d.key === c.gender);
            if (genderObg) {
              genderObg.data.push(c);
            } else {
              clas.data.push({
                key: c.gender,
                data: [c]
              });
            }
          } else {
            acc.push({
              key: c.age,
              data: [
                {
                  key: c.gender,
                  data: [c]
                }
              ]
            });
          }
          return acc;
        }, [])
        .sort((a, b) => a.key - b.key)
        .map(age => {
          age.data
            .sort((a, b) => b.key - a.key)
            .map(genders => {
                const categ = genders.data.sort((a, b) => {
                  if (!Number(b.weight)) {
                    return -1;
                  }
                  return +a.weight - +b.weight;
                })
                  .map(cat => {
                    if (additional[cat.id]) {
                      //cat.personsByPlace.push(...additional[cat.id]);
                      //cat.AllPersonsByPlace.unshift(...additional[cat.id].reverse());
                      cat.AllPersonsByPlace.push(...additional[cat.id]);
                    }
                    cat.categoryResult = this.playersService.getPlayersByPlace(cat.AllPersonsByPlace.reverse());
                    return cat;
                  });

                const last = categ[categ.length - 1];
                const beforeLast = categ[categ.length - 2];

                if (last?.hand === HandEnum.Left && beforeLast?.hand === HandEnum.Right && last.weight === beforeLast.weight) {
                  const plusCateg = categ.splice(-2).reverse();
                  categ.push(...plusCateg);
                }

                genders.data = categ;
                return genders;
              }
            );
          return age;
        })
        .reduce((acc, el) => {
          el.data.forEach(gender => {
            acc.push(...gender.data);
          });
          return acc;
        }, [])
      ),
      tap(res => {
        console.log('Sorted result', res);
      }));
  }

  getListCategoriesResults(): Observable<ICategory[]> {
    return this.loadCategoriesState().pipe(
      take(1),
      map(categories => categories
        .filter(c => c.state === CategoryStateEnum.Completed)
        .map(c => {
          c.categoryResult = this.playersService.getPlayersByPlace(c.AllPersonsByPlace.reverse());
          return c;
        })),
      tap(res => {
        console.log('Category list result', res);
      }));
  }
}
