export enum AgeGroupEnum {
  MiniSubJunior = 1,
  SubJunior = 2,
  Junior = 3,
  Younth = 4,
  Senior = 5,
  Masters = 6,
  GrandMasters = 7,
  SeniorGrandMasters = 8,
  SuperSeniorGrandMasters = 9,
  // пара-армрестлінг
  PIDsitting = 10,
  PIDHsitting = 11,
  PIUstanding = 12,
  PIUjunior23 = 13, 
  PIUHstanding = 14,
  PIUHjunior23 = 15,
  VIVisualstanding = 16,
  VIjunior23 = 17,
  HIHearingstanding = 18, 
  HIjunior23 = 19,
  CPDsitting = 20,
  CPUstanding = 21,
  
}
/* export enum AgeGroupEnum {
  SubJunior = 1,
  Junior = 2,
  Younth = 3,
  Senior = 4,
  Masters = 5,
  GrandMasters = 6,
  SeniorGrandMasters = 7,
  MiniSubJunior = 8,

} */
