import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ICategory } from '@shared/interfaces';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CategoriesService, FightService } from '@modules/dashboard/services';
import { FightGroupEnum, FightTypeEnum } from '@shared/enums';
import { Destroyable } from '@shared/utils';
import { ValuesService } from '@core/services';

@Component({
  selector: 'arm-categories-info',
  templateUrl: './categories-info.component.html',
  styleUrls: ['./categories-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesInfoComponent extends Destroyable {
  FightTypeEnum = FightTypeEnum;
  fightGroups = this.valuesService.fightGroups;
  fightTypes = this.valuesService.fightTypes;
  fights$: Observable<any>;
  category: ICategory;
  selectedPlayer = 0;

  @Input() set catId(id: string) {
    if (id) {
      this.category = this.categoriesService.getCategoriesMap(id);
      this.fights$ = this.fightService.getFightsGrid(id).pipe(
        takeUntil(this.destroyed$),
        map(res => res.reduce((fAcc, f, i) => {
          f.index = i + 1;
          if (fAcc[f.level]) {
            if (fAcc[f.level][f.group]) {
              fAcc[f.level][f.group].push(f);
            } else {
              fAcc[f.level][f.group] = [f];
            }
          } else {
            fAcc[f.level] = {};
            fAcc[f.level][f.group] = [f];
          }
          return fAcc;
        }, [])),
        tap(res => {
          console.log(`Grid - ${this.category.alias}`, res);
        })
      );
    }
  }

  FightGroupEnum = FightGroupEnum;

  constructor(readonly categoriesService: CategoriesService,
              readonly valuesService: ValuesService,
              readonly changeDetectorRef: ChangeDetectorRef,
              readonly fightService: FightService) {
    super();
  }

  selectPlayer(id: number) {
    if (id) {
      this.selectedPlayer = id;
      this.changeDetectorRef.markForCheck();
    }
  }
}
