import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    readonly snackBar: MatSnackBar,
    private zone: NgZone,
  ) {}

  showSuccess(message: string, duration = 3000): void {
    this.zone.run(() => {
      this.snackBar.open(message, null, {
        panelClass: ['success'],
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration
      });
    });
  }

  showError(message: string, duration = 5000): void {
    this.zone.run(() => {
      this.snackBar.open(message, null, {
        panelClass: ['error'],
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration
      });
    });
  }

  showMessage(message: any, duration = 3000): void {
    this.zone.run(() => {
      this.snackBar.open(message, null, {
        panelClass: ['message'],
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration
      });
    });
  }
}
