import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ModalConfirmComponent } from '@shared/components/modal-confirm';

export interface ConfirmOptions {
  header?: string;
  body?: string;
  html?: boolean;
  buttons?: { name?: string }[];
}

@Injectable({ providedIn: 'root' })
export class ModalsService {
  constructor(public dialog: MatDialog) {}
  /**
   * Open confirm popup
   */

  public openConfirmPopup(
    options: ConfirmOptions,
    answer: (res) => void = null
  ): void {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      width: '450px',
      data: options,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (answer) {
        answer(result);
      }
    });
  }
}
