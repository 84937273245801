export enum FightTypeEnum {
  Common = 1,
  ToFinal = 2,
  SemiFinal = 3,
  Final = 4,
  SuperFinal = 5,
  HappyWinner = 6,
  ToSemiFinal = 8,
  FinalHappyWinner = 9,
  SemiFinalHappyWinner = 10,
}
