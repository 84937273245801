import { SortTableCatPipe } from './sort-table-cat.pipe';
import { FilterPlayersPipe } from './filter-players.pipe';
import { FilterPlayerCatPipe } from './filter-player-cat.pipe';

export * from './sort-table-cat.pipe';
export * from './filter-players.pipe';
export * from './filter-player-cat.pipe';

export const SHARED_PIPES = [
  SortTableCatPipe,
  FilterPlayersPipe,
  FilterPlayerCatPipe
];
