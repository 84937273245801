export const ROUTES_DATA = {
  AUTH: {
    url: 'auth',
    title: 'Auth',
    children: {
      SIGN_IN: {
        path: 'sign-in',
        url: 'auth/sign-in',
      },
    },
  },
  DASHBOARD: {
    url: '',
    title: 'Arm app',
    children: {
      TABLES: {
        url: 'tables',
      },
      CATEGORIES: {
        url: 'categories',
      },
      PLAYERS: {
        url: 'players',
      },
      LIST: {
        url: 'list',
        children: {
          PLAYERS: {
            url: 'list/players',
            path: 'players',
          },
          GRID: {
            path: 'grid',
          }
        }
      },
      RESULTS: {
        url: 'results',
        children: {
          PLAYERS: {
            url: 'results/players',
            path: 'players'
          },
          TEAM: {
            path: 'team'
          }
        }
      },
      CODE_GENERATOR: {
        url: 'codes', // :id
      },
      PRINT: {
        url: 'print',
      },
      SCREEN_BROADCAST: {
        url: 'screen',
      },
      SETTINGS: {
        url: 'settings',
      },
      JUDGES: {
        url: 'judges',
      },
      SCHEDULE: {
        url: 'schedule',
      },
      WIZARD: {
        url: 'tables/wizard',
      },
    },
  },
  VIDEO: {
    url: 'video',
    children: {
      TABLES: {
        url: 'video/tables',
        path: 'tables'
      },
      BROADCASTING: {
        url: 'video/broadcasting',
        path: 'broadcasting'
      },
      COMMENTATOR: {
        url: 'video/commentator',
        path: 'commentator'
      },
      FINALS: {
        url: 'video/finals',
        path: 'finals'
      },
      PLAYERS: {
        url: 'video/players',
        path: 'players'
      },
      REFEREE: {
        url: 'video/referee',
        path: 'referee'
      },
    }
  },
  PRINTER: {
    url: 'printer',
    children: {
      PLAYERS: {
        url: 'printer/players-codes',
        path: 'players-codes'
      },
      DIPLOMAS: {
        url: 'printer/diplomas',
        path: 'diplomas'
      },
      RESULTS: {
        url: 'printer/results',
        path: 'results'
      },
      LIST: {
        url: 'printer/players-list',
        path: 'players-list'
      }
    }
  }
};
