import { Injectable } from '@angular/core';
import { ICustomEvent, IReqRes, ITableVideoState } from '@shared/interfaces';
import { ActionEnum, CustomEventReasonEnum, ReqResTypeEnum } from '@shared/enums';
import { SocketService } from '@core/services/socket.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomEventsService {
  tableCategoryMap = new Map();
  tableCategory$ = new BehaviorSubject(null);

  constructor(readonly socketService: SocketService) { }

  processing(res: IReqRes<ICustomEvent>) {
    const { reason, data } = res.data;

    switch (reason) {
      case CustomEventReasonEnum.TableCategory: {
        const { table, category, action } = data as ITableVideoState;
        if (action === ActionEnum.Create) {
          this.tableCategoryMap.set(table, category);
        }
        if (action === ActionEnum.Remove) {
          this.tableCategoryMap.delete(table);
        }
        this.tableCategory$.next(this.tableCategoryMap);
        break;
      }
    }
  }

  sendCustomEvent(data: any, reason: CustomEventReasonEnum) {
    this.socketService.sendSocketMessage<ICustomEvent>(
      ReqResTypeEnum.CUSTOM,
      {
        data,
        reason
      },
    );
  }

  /**
   * Tables
   */
  getTableCategory() {
    return this.tableCategory$.asObservable();
  }
}
