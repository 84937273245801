import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ActionEnum, ReqResTypeEnum } from '../../../shared/enums';
import { SocketService } from '../../../core/services';
import { BehaviorSubject } from 'rxjs';
import { IEvent, IReqRes } from '../../../shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private event$ = new BehaviorSubject<IEvent>(null);

  constructor(readonly socketService: SocketService) {
  }

  processing(res: IReqRes<IEvent>) {
    this.event$.next(res.data);
  }

  getEvent() {
    return this.event$.asObservable();
  }

  saveEvent(data: IEvent, action: ActionEnum) {
    this.socketService.sendSocketMessage<IEvent>(
      ReqResTypeEnum.EVENT,
      data,
      action
    );
  }
}
