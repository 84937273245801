<div class="grid-wrapper">
  <div class="category-name" *ngIf="category">
    {{category.alias}}
  </div>
  <ng-container *ngIf="fights$ | async as fights">
    <div class="fight-groups">
      <div class="block-group" *ngFor="let level of fights; let l = index">
        <ng-container *ngFor="let group of level | keyvalue; let i = index">
          <div class="grid-group">
            <div
              class="group-title">
              <ng-container
                *ngIf="group.value.length === 1 && group.value[0].type !== FightTypeEnum.Common && group.value[0].type !== FightTypeEnum.HappyWinner && !group.value[0].invalid; else groupTitle">
                {{fightTypes[group.value[0].type]}}
              </ng-container>
              <ng-template #groupTitle>
                {{fightGroups[group.key]}}{{l ? FightGroupEnum.B === +group.key ? l - 1 : FightGroupEnum.F === +group.key ? '' : l : '' }}
              </ng-template>
            </div>
            <ul class="fight-list">
              <li *ngFor="let fight of group.value">
                <div class="fight-number">{{fight.index}}</div>
                <div class="fight">
                  <div class="title" [class.marked]="selectedPlayer === fight?.first?.id"
                       (click)="selectPlayer(fight?.first?.id)" [class.winner]="fight?.winner === fight?.first?.id">
                    <div class="name" *ngIf="fight?.first; else noData">
                      {{fight?.first.name}} ({{fight?.first?.team}})
                      <div class="player-id">
                        {{fight?.first?.id}}
                      </div>
                    </div>

                  </div>
                  <div class="divider-1"></div>
                  <div class="title" [class.marked]="selectedPlayer === fight?.second?.id"
                       (click)="selectPlayer(fight?.second?.id)" [class.winner]="fight?.winner === fight?.second?.id">
                    <div class="name" *ngIf="fight?.second; else noData">
                      {{fight?.second?.name}} ({{fight?.second?.team}})
                      <div class="player-id">
                        {{fight?.second?.id}}
                      </div>
                    </div>
                  </div>
                  <!--                <small>({{fightTypes[fight?.type]}}) </small>-->
                </div>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #noData>
  <div class="happy-winner">
    <div>---------</div>
    <div>---------</div>
  </div>
</ng-template>
