export enum ResultTableEnum {
  Default = 1,
  AFU = 2,
  Three = 3,
  Four = 4,
  Five = 5
}
export enum ListTableEnum {
  One = 1,
  Two = 2,
}
