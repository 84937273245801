import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IEvent } from '@shared/interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionEnum, AgeGroupEnum } from '@shared/enums';
import { map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EventService } from '@modules/dashboard/services';
import { AuthService, ValuesService } from '@core/services';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'arm-create-edit-event',
  templateUrl: './create-edit-event.component.html',
  styleUrls: ['./create-edit-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEditEventComponent implements OnInit {
  currentEvent: IEvent;
  form: FormGroup;
  event$: Observable<IEvent> = this.eventService.getEvent().pipe(
    tap(res => {
      this.buildForm(res);
    })
  );
  ageGroup = this.valuesService.ageGroup;

  @Input() set event(event: IEvent) {
    this.buildForm(event);
  }

  @Output() closeForm = new EventEmitter<boolean>();

  constructor(
    readonly authService: AuthService,
    readonly valuesService: ValuesService,
    readonly activatedRoute: ActivatedRoute,
    readonly eventService: EventService,
    readonly location: Location,
    readonly changeDetector: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
  }

  buildForm(event: IEvent = null) {
    this.currentEvent = event;
    const places = event?.settings?.placesScore;
    this.form = new FormGroup({
      name: new FormControl(event?.name || '', Validators.required),
      place: new FormControl(event?.place || '', Validators.required),
      startDate: new FormControl(event?.startDate || null, Validators.required),
      endDate: new FormControl(event?.endDate || null, Validators.required),
      age: new FormControl(
        event?.age || [AgeGroupEnum.Senior],
        Validators.required
      ),
      id: new FormControl(event?.id || null),
      placesScore: new FormGroup({
        1: new FormControl(places?.['1'] || 10),
        2: new FormControl(places?.['2'] || 7),
        3: new FormControl(places?.['3'] || 5),
        4: new FormControl(places?.['4'] || 4),
        5: new FormControl(places?.['5'] || 3),
        6: new FormControl(places?.['6'] || 2),
        7: new FormControl(places?.['7'] || 1),
        8: new FormControl(places?.['8'] || 0),
      })
    });
    this.changeDetector.markForCheck();
  }

  saveEvent() {
    if (this.form.valid) {
      this.eventService.saveEvent(

        {
          ...this.form.value,
          settings: {
            ...this.currentEvent?.settings,
            placesScore: this.form.value.placesScore
          }
        },
        ActionEnum.Update
      );
    }
  }

  cancelSave() {
    this.location.back();
  }
}
