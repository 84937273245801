<mat-toolbar class="toolbar" color="primary">
  <!-- <div>
    <button class="mobile" mat-icon-button (click)="openSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div> -->
  <button class="button" mat-icon-button (click)="toggleSideBar()">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="first-block">
    <img class="img-logo" src="/assets/Logo ArmRec_white2.svg" />

    <span class="pas">Professional Armwrestling Software</span>

  </div>

  <div class="settings-button">
    <button class="icon-set" mat-button [matMenuTriggerFor]="menu">
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="settingsMenu()">
        <mat-icon>settings</mat-icon>
        Settings
      </button>

      <button mat-menu-item (click)="openAbout()">
        <mat-icon>info</mat-icon>
        About
      </button>
      <button mat-menu-item>
        <mat-icon>help_outline</mat-icon>
        Tutorial
      </button>

      <button mat-menu-item [matMenuTriggerFor]="langMenu">
        <mat-icon>language</mat-icon>
        Language
      </button>
      <mat-menu #langMenu="matMenu">
        <button mat-menu-item (click)="selectLang(LangEnum.EN)">
          EN
        </button>
        <button mat-menu-item (click)="selectLang(LangEnum.UA)">
          UA
        </button>
      </mat-menu>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        Logout
      </button>

    </mat-menu>
  </div>


</mat-toolbar>
