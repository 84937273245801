import { BehaviorSubject } from 'rxjs';
import { ActionEnum } from '../enums';
import { IReqRes } from '../interfaces';

export function processEntity<T extends { id?: any }>(
  subj: BehaviorSubject<T[]>,
  res: IReqRes<T>
): void {
  const {
    data,
    req: { action },
  } = res;
  if (action) {
    if (action === ActionEnum.Create) {
      subj.next([data, ...subj.getValue()]);
    }
    if (action === ActionEnum.Update) {
      subj.next([
        ...subj.getValue().map((d) => {
          if (+d.id === +data.id) {
            return data;
          }
          return d;
        }),
      ]);
    }
    if (action === ActionEnum.Remove) {
      subj.next([...subj.getValue().filter((d) => +d.id !== +data.id)]);
    }
  } else {
    subj.next([data, ...subj.getValue()]);
  }
}

export function processMapEntity<T extends { id?: any }>(
  map: any,
  res: IReqRes<T>
): void {
  const {
    data,
    req: { action },
  } = res;
  if (action === ActionEnum.Remove) {
    if (map.has(data.id)) {
      map.delete(data.id);
    }
  } else {
    map.set(data.id, data);
  }
}

export function deepClone(data: any) {
  return JSON.parse(JSON.stringify(data));
}
