import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES_DATA } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(readonly router: Router) {}

  login(password: string) {
    localStorage.setItem('logged', btoa(unescape(encodeURIComponent(password))));
    this.router.navigateByUrl(`/${ROUTES_DATA.DASHBOARD.url}`);
  }

  logout() {
    localStorage.removeItem('logged');
    this.router.navigateByUrl(`/${ROUTES_DATA.AUTH.url}`);
  }

  logged() {
    return localStorage.getItem('logged') === btoa(unescape(encodeURIComponent('armREC2024!')));
  }

  selectEvent(eventId: number) {
    localStorage.setItem('event', `${eventId}`);
    this.router.navigateByUrl(`/${ROUTES_DATA.DASHBOARD.url}`);
  }

  getEvenId() {
    return +localStorage.getItem('event');
  }

  eventSelected() {
    return !!localStorage.getItem('event');
  }
}
