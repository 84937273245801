import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ROUTES_DATA } from 'src/app/shared/constants';


@Component({
  selector: 'arm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {

    ROUTES_DATA = ROUTES_DATA;
    menu = [
      {
        icon: 'armtableIcon',
        title: 'Tables',
        url: ROUTES_DATA.DASHBOARD.children.TABLES.url,
      },
      {
        icon: 'categoriesIcon',
        title: 'Categories',
        url: ROUTES_DATA.DASHBOARD.children.CATEGORIES.url,
      },
      {
        icon: 'playersIcon',
        title: 'Players',
        url: ROUTES_DATA.DASHBOARD.children.PLAYERS.url,
      },
      {
        icon: 'listIcon',
        title: 'List',
        url: ROUTES_DATA.DASHBOARD.children.LIST.children.PLAYERS.url,
      },
      {
        icon: 'resultIcon',
        title: 'Results',
        url: ROUTES_DATA.DASHBOARD.children.RESULTS.children.PLAYERS.url,
      },
      {
        icon: 'printIcon',
        title: 'Print',
        url: ROUTES_DATA.DASHBOARD.children.PRINT.url,
      },

      {
        icon: 'screenbroadcastIcon',
        title: 'Screen broadcast',
        url: ROUTES_DATA.DASHBOARD.children.SCREEN_BROADCAST.url,
      },
      {
        icon: 'judgeIcon',
        title: 'Referees',
        url: ROUTES_DATA.DASHBOARD.children.JUDGES.url,
      },
      {
        icon: 'scheduleIcon',
        title: 'Schedule',
        url: ROUTES_DATA.DASHBOARD.children.SCHEDULE.url,
      },
    ];


  constructor(){
  }

  ngOnInit(): void {
  }

}
