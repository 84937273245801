import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IFight, IFightActions, IReqRes } from '../../../shared/interfaces';
import { ActionEnum, CategoryStateEnum, FightTypeEnum, ReqResTypeEnum } from '../../../shared/enums';
import { SocketService } from '../../../core/services';
import { HttpClient } from '@angular/common/http';
import { PlayersService } from '@modules/dashboard/services/players.service';
import { filter, map, share } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class FightService {
  private catTableState$ = new BehaviorSubject({} as any);

  constructor(
    readonly socketService: SocketService,
    readonly http: HttpClient,
    readonly playersService: PlayersService,
  ) {
  }

  processingNext(res: IReqRes<any>) {
    const {data, req} = res;
    const fights = data.fights;
    const categoryState = data.categoryState;
    const prev = this.mapFight(req.payload?.prev);
    const curr = this.mapFight(fights?.[0]);
    let next = this.mapFight(fights?.[1]);
    const catId = req.payload.catId;

    const state = this.catTableState$.getValue();

    const updateState = (nextFight = null) => {
      next = nextFight || next;
      if (state[catId]) {
        const update = state[catId];
        update.prev = prev;
        update.curr = curr;
        update.next = next;
        update.categoryState = categoryState;
        this.catTableState$.next({
          ...this.catTableState$.getValue(),
          ...update
        });
      } else {
        this.catTableState$.next({
          ...this.catTableState$.getValue(),
          [catId]: {
            prev,
            curr,
            next,
            categoryState
          }
        });
      }
    };

    if (!next?.first || !next?.second) {
      this.getNextFights(catId).subscribe(nextFights => {
        updateState(nextFights?.[1]);
      });
    } else {
      updateState();
    }

    if (categoryState !== CategoryStateEnum.Completed && !prev) {
      this.getPrev(catId);
    }
  }

  processingPrev(res: IReqRes<any>) {
    const {data, req} = res;
    const fights = data.fights;
    const catId = req.payload.catId;

    const state = this.catTableState$.getValue();
    if (state[catId]) {
      state[catId].prev = this.mapFight(fights?.[0]);
    } else {
      state[catId] = {
        prev: this.mapFight(fights?.[0])
      };
    }
    this.catTableState$.next(state);
  }

  processingFight(res: IReqRes<IFight>) {
    const {req, index, data} = res;
    if (index) {
      if (index === 1) {
        this.getNext(data.category);
      }
      return;
    }
    if (data.winner) {
      this.getNext(data.category, data);
    } else {
      this.getNext(data.category);
    }
  }

  getCatTableData(catId: string) {
    return this.catTableState$.asObservable().pipe(
      share(),
      filter(res => res[catId]),
      map(res => res[catId])
    );
  }

  getCatTableDataValue() {
    return this.catTableState$.getValue();
  }

  startDraw(category: string, reset = false) {
    const data = {category};
    this.socketService.sendSocketMessage<{
      category: string
    }>(
      ReqResTypeEnum.DRAW,
      data,
      (reset ? ActionEnum.Create : null)
    );
  }

  fightAction(data: IFightActions) {
    this.socketService.sendSocketMessage<IFightActions>(
      ReqResTypeEnum.FIGHT,
      data
    );
  }

  getPrev(category: string, count = 1) {
    this.socketService.sendSocketMessage<{ category: string, count?: number, payload: any }>(
      ReqResTypeEnum.PREV,
      {
        category,
        count,
        payload: {
          catId: category
        }
      }
    );
  }

  getNext(category: string, prev: IFight = null, count = 2) {
    this.socketService.sendSocketMessage<{ category: string, count?: number, payload: any }>(
      ReqResTypeEnum.NEXT,
      {
        category,
        count,
        payload: {
          catId: category,
          prev
        }
      }
    );
  }

  resetFight(id: string) {
    this.socketService.sendSocketMessage<{ id: string, action?: ActionEnum }>(
      ReqResTypeEnum.FIGHT,
      {
        id,
        action: ActionEnum.Reset,
      },
    );
  }

  mapFight(data) {
    return data ? {
      ...data,
      first: this.playersService.getPlayer(+data.first),
      second: data.second ? this.playersService.getPlayer(+data.second) : null,
    } as IFight : null;
  }

  getFightsGrid(catId: string) {
    return this.http.get<any>(`${environment.apiUrl}view/${catId}`).pipe(
      map(fights => fights.map(f => this.mapFight(f)))
    );
  }

  getNextFights(catId: string) {
    return this.http.get<any>(`${environment.apiUrl}next/${catId}`).pipe(
      map(fights => fights.map(f => this.mapFight(f)))
    );
  }
}
